'use client'
// import tech from '@/app/(pages)/service/data/tech.json';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import Socialmedia from './Sociallmedia';

interface TechItem {
  title: string;
  description: string;
  image: string;
  link: string;
}

export default function Header2() {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);


  const toggle1 = () => { setIsOpen1(prevState => !prevState); };
  const toggle2 = () => { setIsOpen2(prevState => !prevState); };
  const toggle3 = () => { setIsOpen3(prevState => !prevState); };
  const toggle4 = () => { setIsOpen4(prevState => !prevState); };


  // const techs = Object.values(tech);

  const solutionsData = {
    'inventory': {
      title: "Inventory & Warehouse",
      description: "Manage your inventory and warehouse operations efficiently.",
      image: "/assets/images/solution/jbcodeapp_solution_inventory_warehouse_management.webp",
      link: "/solutions"
    },
    'financial': {
      title: "Financial Accounting",
      description: "Streamline your financial accounting processes with our solution.",
      image: "/assets/images/solution/jbcodeapp_solution_financial_accounting_software.webp",
      link: "/solutions"
    },
    'vehicle': {
      title: "Vehicle Tracking System",
      description: "Track and manage your fleet effectively.",
      image: "/assets/images/solution/jbcodeapp_solution_transport_management_system.webp",
      link: "/solutions"
    },
    'transport': {
      title: "Transport Management Solutions",
      description: "Optimize your transport operations with advanced solutions.",
      image: "/assets/images/solution/jbcodeapp_solution_transport_management_system.webp",
      link: "/solutions"
    },
    'hrms': {
      title: "HR Management System (HRMS)",
      description: "Manage your human resources efficiently with our HRMS.",
      image: "/assets/images/solution/jbcodeapp_solution_human_resources_management_system.webp",
      link: "/solutions"
    },
    'smart-station': {
      title: "Smart Station Management",
      description: "Enhance your station management with smart solutions.",
      image: "/assets/images/solution/jbcodeapp_solution_inventory_warehouse_management.webp",
      link: "/solutions"
    },
    'customer-relation': {
      title: 'Customer Relationship Management (CRM)',
      description: 'Manage and improve interactions with customers.',
      image: '/assets/images/solution/jbcodeapp_solution_financial_accounting_software.webp',
      link: '/solutions'
    },
    'smart-estate': {
      title: 'Smart Estate Management',
      description: 'Efficiently manage real estate and property.',
      image: '/assets/images/solution/jbcodeapp_solution_transport_management_system.webp',
      link: '/solutions'
    },
    'procurement': {
      title: 'Procurement Management System',
      description: 'Streamline and control purchasing processes.',
      image: '/assets/images/solution/jbcodeapp_solution_transport_management_system.webp',
      link: '/solutions'
    },
    'real-estate': {
      title: 'Real Estate & Construction',
      description: 'Solutions for real estate and construction industries.',
      image: '/assets/images/solution/jbcodeapp_solution_inventory_warehouse_management.webp',
      link: '/solutions'
    },
    'sales-invoice': {
      title: 'Sales & Invoice Processing',
      description: 'Handle sales and manage invoices easily.',
      image: '/assets/images/solution/jbcodeapp_solution_human_resources_management_system.webp',
      link: '/solutions'
    },
    'visitor-management': {
      title: 'Visitor Management System',
      description: 'Track and manage visitors at your premises.',
      image: '/assets/images/solution/jbcodeapp_solution_financial_accounting_software.webp',
      link: '/solutions'
    }

  };

  const [selectedSolution, setSelectedSolution] = useState<TechItem | null>(null);

  const handleMouseOver = (key) => {
    setSelectedSolution(solutionsData[key]);
  };

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 390 && window.innerHeight <= 844);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {/* Header area start */}
      <header className="header__area-2">
        <div className="header__inner-2">
          <div className="header__logo-2">
            <a href="/">
              {/* Logo */}
              {/* <img src="/assets/imgs/logo/site-logo-white-2.png" alt="Site Logo" /> */}
              <img
                // src="/assets/jbcodeapp_logo_white_no_space.webp"
                src="/assets/jbcodeapp_logo_white_logo.png"
                alt="Site Logo"
                className=' h-auto w-auto'
              />
            </a>
          </div>
          <div className="header__nav-2">
            <ul className="main-menu menu-anim " style={{ textAlign: "center" }} >
              {/* Pages */}
              {/* <li>
                <a href="/">Pages</a>
                <ul className="main-dropdown">
                  <li>
                    <a href="/tags">Tags</a>
                  </li>
                  <li>
                    <a href="/focus">Focus</a>
                  </li>
                  <li>
                    <a href="/technology">Technology</a>
                  </li>
                  <li>
                    <a href="/sitemap">Sitemap</a>
                  </li>
                  <li>
                    <a href="/inquiry">Inquiry</a>
                  </li>
                  <li>
                    <a href="/services/hire-fullstack-dedicated-developers">hire-fullstack-dedicated-developers</a>
                  </li>
                  <li>
                    <a href="/services/hire-web-developers">hire-web-developers</a>
                  </li>
                  <li>
                    <a href="/services/hire-frontend-developers">hire-frontend-developers</a>
                  </li>
                  <li>
                    <a href="/services/hire-backend-developers">hire-backend-developers</a>
                  </li>
                </ul>
              </li> */}

              {/* HIRE DEVELOPERS */}
              <li className="has-megamenu">
                <a href="/services/hire-dedicated-developers">HIRE DEVELOPERS</a>
                <ul className="mega-menu-2">
                  {/* Hire Website Developers */}
                  <li className="!min-w-full">
                    <div className="menu-heading text-black border-b">
                      <div className="relative group inline-block">
                        <span className="whitespace-nowrap">Hire Website Developers</span>
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>
                    <ul className="p-0 m-0 list-none">
                      <li className="whitespace-nowrap">
                        <a href="/services/web/hire-php-web-developers" className="block">Hire PHP Developers</a>
                      </li>
                      <li className="whitespace-nowrap">
                        <a href="/services/web/hire-laravel-web-developers" className="block">Hire Laravel Developers</a>
                      </li>
                      <li className="whitespace-nowrap">
                        <a href="#" className="block">Hire NodeJS Developers</a>
                      </li>
                      <li className="whitespace-nowrap">
                        <a href="#" className="block">Hire ExpressJS Developers</a>
                      </li>
                    </ul>
                  </li>

                  {/* Hire Mobile Developers */}
                  <li className="!min-w-full">
                    <div className="menu-heading text-black border-b">
                      <div className="relative group inline-block">
                        <span className="whitespace-nowrap">Hire Mobile Developers</span>
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <a href="#">Hire iOS Developers</a>
                      </li>
                      <li>
                        <a href="#">Hire Android Developers</a>
                      </li>
                      <li>
                        <a href="#">Hire Flutter Developers</a>
                      </li>
                      <li>
                        <a href="#">Hire Swift Developers</a>
                      </li>
                    </ul>
                  </li>

                  {/* Hire Frontend Developers */}
                  <li className="!min-w-full">
                    <div className="menu-heading text-black border-b">
                      <div className="relative group inline-block">
                        <span className="whitespace-nowrap">Hire Frontend Developers</span>
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <a href="/services/web/hire-reactjs-developers" className="whitespace-nowrap">Hire ReactJS Developers</a>
                      </li>
                      <li>
                        <a href="#" className="whitespace-nowrap">Hire Angular Developers</a>
                      </li>
                      <li>
                        <a href="#" className="whitespace-nowrap">Hire NextJS Developers</a>
                      </li>
                      <li>
                        <a href="#" className="whitespace-nowrap">Hire VueJS Developers</a>
                      </li>
                    </ul>
                  </li>

                  {/* Hire Open Source Developers */}
                  <li className="!min-w-full">
                    <div className="menu-heading text-black border-b">
                      <div className="relative group inline-block">
                        <span className="whitespace-nowrap">Hire Open Source Developers</span>
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>
                    <ul className="!min-w-full">
                      <li>
                        <a href="#">Hire Wordpress Developers</a>
                      </li>
                      <li>
                        <a href="#">Hire Shopify Developers</a>
                      </li>
                      <li>
                        <a href="#">Hire Magento Developers</a>
                      </li>
                      <li>
                        <a href="#">Hire Drupal Developers</a>
                      </li>
                    </ul>

                    {/* Contact Us */}
                    {/* <div className="flex flex-col border-t border-gray-300 items-center justify-center text-center">
                      <p className="text-md pt-2 pb-2">Start with experts</p>
                      <a
                        className="px-6 py-2 rounded-md bg-white hover:bg-[#c9f31d] border border-gray-500 text-gray-600 font-medium transition-colors duration-300"
                        href="contact"
                      >
                        Contact Us
                      </a>
                    </div> */}
                  </li>
                </ul>
              </li>


              {/* Services */}
              <li className="has-megamenu ">
                <a href="/what-we-do" >
                  SERVICES
                </a>
                <ul className="mega-menu-2  ">
                  {/* what we do/services */}
                  <li className='!min-w-full'>
                    <div className="menu-heading text-black border-b ">
                      <div className="relative group inline-block">
                        <a href="/what-we-do" className="text-b relative z-10 hover:text-[#c9f31d]">
                          What We Do
                        </a>
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>


                    <ul>
                      <li>
                        <a href="/what-we-do/web-development">Web Development</a>
                      </li>
                      <li>
                        <a href="/what-we-do/mobile-app-development">Mobile App Development</a>
                      </li>
                      <li>
                        <a href="/what-we-do/design">UI/UX Designs</a>
                      </li>
                      <li>
                        <a href="/what-we-do/ai-and-ml-services" >
                          AI & ML App Development
                        </a>
                      </li>
                      <li>
                        <a href="/services/digital-marketing-services" >
                          Digital Marketing
                        </a>
                      </li>
                      <li>
                        <a href="/services/hire-dedicated-developers" >
                          Hire Dedicated
                          Developers
                        </a>
                      </li>
                    </ul>
                    {/* <hr />
                    <div className="menu-heading text-black">Agency Dark</div>
                    <ul>
                      <li>
                        <a href="index-dark.html">Digital Maketing</a>
                      </li>
                      <li>
                        <a href="index-2-dark.html">Design Studio</a>
                      </li>
                      <li>
                        <a href="index-3-dark.html">Digital Agency</a>
                      </li>
                      <li>
                        <a href="index-7-dark.html">creative Agency</a>
                      </li>
                      <li>
                        <a href="index-dark.html">Digital Maketing</a>
                      </li>
                      <li>
                        <a href="index-2-dark.html">Design Studio</a>
                      </li>
                      <li>
                        <a href="index-3-dark.html">Digital Agency</a>
                      </li>
                      <li>
                        <a href="index-7-dark.html">creative Agency</a>
                      </li>
                    </ul> */}
                  </li>
                  {/* Industries */}
                  <li className='!min-w-full'>
                    <div className="menu-heading text-black border-b ">
                      <div className="relative group inline-block">
                        <a href="/industries" className="text-b relative z-10 hover:text-[#c9f31d]">
                          Industries
                        </a>
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <a href="/">Education / e-learning</a>
                      </li>
                      <li>
                        <a href="/">Healthcare</a>
                      </li>
                      <li>
                        <a href="/">Logistics & Distribution</a>
                      </li>
                      <li>
                        <a href="/">Finance & Insurance</a>
                      </li>
                      <li>
                        <a href="/">Legal</a>
                      </li>
                      <li>
                        <a href="/">Real Estate & Construction</a>
                      </li>
                    </ul>
                  </li>

                  {/* Focus */}
                  <li className='!min-w-full'>
                    <div className="menu-heading text-black border-b ">
                      <div className="relative group inline-block">
                        <a href="/focus" className="text-b relative z-10 hover:text-[#c9f31d]">
                          Focus
                        </a>
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>
                    <ul className='!min-w-full'>
                      <li>
                        <a href="/">AI Solutions</a>
                      </li>
                      <li>
                        <a href="/">Enterprise Software </a>
                      </li>
                      <li>
                        <a href="/">Tech Integration Services</a>
                      </li>
                      <li>
                        <a href="/">Automated Business </a>
                      </li>
                      <li>
                        <a href="/">E-Commerce Solutions</a>
                      </li>
                      <li>
                        <a href="/">Business Analytics</a>
                      </li>
                    </ul>

                  </li>

                  {/* Design */}
                  <li className=''>
                    <div className='bg-gradient-to-b from-zinc-600 via-slate-50 to-zinc-400 rounded-md px-3 py-2' >

                      <img src="/assets/images/jbcodeapp_methodology.webp"
                        className='pt-4 pb-3 borderpx-2 py-2'
                        alt="jbcodeapp solution" />

                      <div className="menu-heading text-black text-center">
                        <a href="/">
                          Smart Solutions
                        </a>

                      </div>
                      {/* <p className="sec-text text-black pb-3">
                        Clarify Project Goals for Accurate Costs and Timely Completion
                      </p> */}

                      {/* <div className='py-3'>
                        <a
                          href="what-we-do"
                          className="border-2 border-[#c9f31d] hover:border-white border-solid rounded-lg px-4 py-2 bg-black text-white "
                        >
                          Get More
                        </a>
                      </div> */}
                    </div>
                  </li>
                  {/* Contact Info */}
                  {/* <li className='px-4 !min-w-full'>
                    <div className="menu-heading text-black border-b ">
                      <div className="relative group inline-block">
                        <a href="/industries" className="text-b relative z-10 hover:text-[#c9f31d]">
                          Contact
                        </a>
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>
                    <ul className=''>
                      <li className='!min-w-full'>
                        <Link href="tel:+919909918338" className='hover:text-amber-500'>
                          <div className="blog__detail-meta">
                          
                            <h5>
                              (+91)990-991-8338
                            </h5>
                          </div>
                        </Link>
                      </li>
                      <li className='!min-w-full'>
                        <Link href="mailto:contact@jbcodeapp.com">
                          <div className="blog__detail-meta">
                           
                            <h5 className='text-md'>
                              contact@jbcodeapp.com
                            </h5>
                          </div>
                        </Link>
                      </li>

                      <Socialmedia />

                      <div className='px-4 py-4 text-center items-center justify-center'>
                        <a
                          className="px-3 py-3 rounded-md bg-white hover:bg-[#c9f31d]  border border-gray-500 text-gray-400  "
                          href="contact">
                          Contact Us
                        </a>
                      </div>

                    </ul>
                  </li> */}

                </ul>
                <ul>

                </ul>
              </li>

              {/* Solution */}
              <li className="has-megamenu">
                <a href="/solutions" >
                  SOLUTIONS
                </a>
                <ul className="mega-menu-2">

                  {/* Solution */}
                  <li className='!min-w-min'>
                    <div className="menu-heading text-black border-b">
                      <div className="relative group inline-block">
                        {/* <Link href="/solutions" className="text-b relative z-10 hover:text-[#c9f31d]"> */}
                        Customise Solutions
                        {/* </Link> */}
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>

                    <ul>
                      <li onMouseOver={() => handleMouseOver('inventory')}>
                        <a href="/solutions">Inventory & Warehouse</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('financial')}>
                        <a href="/solutions">Financial Accounting</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('vehicle')}>
                        <a href="/solutions">Vehicle Tracking System</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('transport')}>
                        <a href="/solutions">Transport Management Solutions</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('hrms')}>
                        <a href="/solutions">HR Management System (HRMS)</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('smart-station')}>
                        <a href="/solutions">Smart Station Management</a>
                      </li>
                    </ul>
                  </li>

                  {/* 2nd Line */}
                  <li className='!min-w-min'>
                    <div className="menu-heading text-black">
                      <div className="relative group inline-block">
                        {/* <Link href="/industries" className="text-b relative z-10 hover:text-[#c9f31d]"> */}
                        {/* No content needed here */}
                        {/* </Link> */}
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>
                    {/* <ul>
                      <li><a href="/industries/legal">Customer Relationship Management (CRM)</a></li>
                      <li><a href="/industries/logistics">Smart Estate Management</a></li>
                      <li><a href="/industries/finance">Procurement Management System</a></li>
                      <li><a href="/industries/real-estate">Real Estate & Construction</a></li>
                      <li><a href="/industries/education">Sales & Invoice Processing</a></li>
                      <li><a href="/industries/healthcare">Visitor Management System</a></li>
                    </ul> */}
                    <ul>
                      <li onMouseOver={() => handleMouseOver('customer-relation')}>
                        <a href="/solutions">Customer Relationship Management (CRM)</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('smart-estate')}>
                        <a href="/solutions">Smart Estate Management</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('procurement')}>
                        <a href="/solutions">Procurement Management System</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('real-estate')}>
                        <a href="/solutions">Real Estate & Construction</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('sales-invoice')}>
                        <a href="/solutions">Sales & Invoice Processing</a>
                      </li>
                      <li onMouseOver={() => handleMouseOver('visitor-management')}>
                        <a href="/solutions">Visitor Management </a>
                      </li>
                    </ul>

                  </li>

                  <li className='!w-0'>
                    {/* <div className="absolute top-0 bottom-0 left-0 w-px bg-neutral-500" /> */}
                  </li>


                  {/* Featured Solution */}
                  <li className='!min-w-[350px] !ml-[-30%] flex flex-col '>
                    {/* <div className='bg-gradient-to-b from-zinc-600 via-slate-50 to-zinc-400 rounded-md px-3 py-2 w-[-50%]'> */}
                    <img src={selectedSolution ? selectedSolution.image : '/assets/images/solution/jbcodeapp_solution_transport_management_system.webp'}
                      className='pt-5 pb-2 rounded-md w-screen' alt={selectedSolution ? selectedSolution.title : 'Solution'} />

                    <div className="menu-heading text-black ">
                      {/* <a href={selectedSolution ? selectedSolution.link : "/"}> */}
                      {selectedSolution ? selectedSolution.title : 'Smart Solutions'}
                      {/* </a> */}
                    </div>
                    <p className="sec-text text-black pb-3 !min-w-fit">
                      {selectedSolution ? selectedSolution.description : 'Clarify Project Goals for Accurate Costs and Timely Completion'}
                    </p>

                    {/* <div className='py-3'>
                      <a
                        href={selectedSolution ? selectedSolution.link : "/"}
                        className="border-2 border-[#c9f31d] hover:border-white border-solid rounded-lg px-4 py-2 bg-black text-white hover:bg-white hover:text-black"
                      >
                        View More
                      </a>
                    </div> */}
                    {/* </div> */}
                  </li>

                  {/* Design */}
                  {/* <li className='!min-w-full'>
                    <div className='bg-gradient-to-b from-zinc-600 via-slate-50 to-zinc-400 rounded-md px-3 py-2' >

                      <img src="/assets/images/jbcodeapp_solutions.png"
                        className='pt-4 pb-3 borderpx-2 py-2'
                        alt="jbcodeapp solution" />

                      <div className="menu-heading text-black text-center">
                        <a href="/">
                          Smart Solutions
                        </a>

                      </div>
                     

                      <div className="flex items-center justify-center py-3">
                        <a
                          href="/solutions"
                          className="border-2 border-[#c9f31d] hover:border-white border-solid rounded-lg px-4 py-2 bg-black text-white transition-colors duration-300"
                        >
                          Get More
                        </a>
                      </div>

                    </div>
                  </li> */}

                  {/* Contact Info */}
                  {/* <li className='px-4'>
                    <ul>
                      <li>
                        <Link href="tel:+919909918338" className='hover:text-amber-500'>
                          <div className="blog__detail-meta">
                            <img src="/assets/images/icon/mobile.png" alt="Phone Icon" className='h-12' />
                            <h5>(+91)990-991-8338</h5>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link href="mailto:contact@jbcodeapp.com">
                          <div className="blog__detail-meta">
                            <img src="/assets/images/icon/email.png" alt="Email Icon" className='h-12' />
                            <h5 className='text-md'>contact@jbcodeapp.com</h5>
                          </div>
                        </Link>
                      </li>

                      <Socialmedia />

                      <div className='px-4 py-4 text-center items-center justify-center'>
                        <a
                          className="px-3 py-3 rounded-md bg-white hover:bg-[#c9f31d] border border-gray-500 text-gray-400"
                          href="contact"
                        >
                          Contact Us
                        </a>
                      </div>
                    </ul>
                  </li> */}
                </ul>
              </li>

              {/* Our Work */}
              <li>
                <a href="/portfolio">OUR WORK</a>

                <ul className="mega-menu-2 ">
                  {/* Case Studies */}
                  <li className='!min-w-full'>
                    <div  >
                      <div className="menu-heading text-black border-b ">
                        <div className="relative group inline-block">
                          Case Studies
                          <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                        </div>
                      </div>

                      <a href="/case-studies" className="text-b relative z-10 hover:text-[#c9f31d]">
                        <img
                          src="/assets/images/jbcodeapp_case_studies.webp"
                          className=' pb-1 hover:bg-neutral-300 mt-3 rounded-lg border border-black hover:border-black'
                          alt="jbcodeapp case studies" />
                      </a>

                    </div>
                  </li>


                  {/* Portfolio */}
                  <li className='!min-w-full'>
                    <div>
                      <div className="menu-heading text-black border-b ">
                        <div className="relative group inline-block">
                          Portfolio
                          <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                        </div>
                      </div>

                      <a href="/portfolio" className="text-b relative z-10 hover:text-[#c9f31d]">
                        <img
                          src="/assets/images/jbcodeapp_our_work.svg"
                          className=' pb-1 hover:bg-neutral-300 mt-3 rounded-lg border border-black hover:border-black'
                          alt="jbcodeapp our work Image"
                        />
                      </a>

                    </div>
                  </li>

                  {/* Products */}
                  <li className='!min-w-full'>
                    <div  >
                      <div className="menu-heading text-black border-b ">
                        <div className="relative group inline-block">
                          Products
                          <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                        </div>
                      </div>

                      <a href="/" className="text-b  z-10 hover:text-[#c9f31d]">
                        <img
                          src="/assets/images/jbcodeapp_product.webp"
                          className='pb-1 hover:bg-neutral-300 mt-3 rounded-lg border border-black hover:border-black'
                          alt="jbcodeapp product Image" />
                      </a>

                    </div>
                  </li>

                  {/* Testimonials */}
                  <li className='!min-w-full'>
                    <div className="menu-heading text-black border-b ">
                      <div className="relative group inline-block">
                        Client Testimonials
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>

                    <div className="testimonial__info-4 pt-5">
                      <div className='  px-2 py-2 rounded-lg hover:bg-neutral-300'>

                        <p className=''>
                          We were there from the start of SEO's rise and have since grown to a team of over 50 talented specialists with diverse skills and extensive experience.
                        </p>
                        <img
                          className=" !h-10 !w-10 "
                          src="/assets/images/jbcodeapp_testimonials_foreign_man.webp"
                          alt="Testimonial Image"
                        />
                        <h5 className="testimonial__name-4 py-2"> Cameron Delacruz </h5>
                        <h6 className="testimonial__role-4 "> Florida, United State </h6>
                      </div>

                    </div>
                  </li>

                  {/* Contact Info */}
                  {/* <li className='px-4 '>
                    <ul className=''>
                      <li>
                        <Link href="tel:+919909918338" className='hover:text-amber-500'>
                          <div className="blog__detail-meta">
                            <img
                              src="/assets/images/icon/mobile.png"
                              alt="Author Picture"
                              className='h-12'
                            />
                            <h5>
                              (+91)990-991-8338
                            </h5>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link href="mailto:contact@jbcodeapp.com">
                          <div className="blog__detail-meta">
                            <img
                              src="/assets/images/icon/email.png"
                              alt="Author Picture"
                              className='h-12'
                            />
                            <h5 className='text-md'>
                              contact@jbcodeapp.com
                            </h5>
                          </div>
                        </Link>
                      </li>

                      <Socialmedia />

                      <div className='px-4 py-4 text-center items-center justify-center'>

                        <a
                          className="px-3 py-3 rounded-md bg-white hover:bg-[#c9f31d]  border border-gray-500 text-gray-400  "
                          href="contact">
                          Contact Us
                        </a>
                      </div>

                    </ul>
                  </li> */}

                </ul>

              </li>

              {/* Company */}
              <li className="has-megamenu ">
                <a href="/" >
                  COMPANY
                </a>
                <ul className="mega-menu-2 ">
                  {/* what we do */}
                  <li>
                    <div className="menu-heading text-black border-b ">
                      <div className="relative group inline-block">
                        {/* <Link href="/what-we-do" className="text-b relative z-10 hover:text-[#c9f31d]"> */}
                        Overview
                        {/* </Link> */}
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>

                    <ul className='!min-w-full'>
                      <li>
                        <a href="/about">About Us</a>
                      </li>
                      <li>
                        <a href="/how-to-engage" > How We Work </a>
                      </li>
                      <li>
                        <a href="/career">Career</a>
                      </li>
                      <li>
                        <a href="/methodologies" >  Methodology  </a>
                      </li>
                    </ul>

                    {/*                     
                    <div className="flex justify-center items-center py-2">
                      <hr />
                      <div className="flex space-x-4">
                        <img
                          src="/assets/images/icon/googlerating.webp"
                          className="h-24"
                          alt="Google Rating"
                        />
                        <img
                          src="/assets/images/icon/upwork-rating.png"
                          className="h-24"
                          alt="Upwork Rating"
                        />
                        <img
                          src="/assets/images/icon/clutch-rating.png"
                          className="h-24"
                          alt="Clutch Rating"
                        />
                      </div>
                    </div> */}




                  </li>

                  {/* Insights */}
                  <li className='!min-w-full'>
                    <div className="menu-heading text-black border-b ">
                      <div className="relative group inline-block">
                        {/* <Link href="/industries" className="text-b relative z-10 hover:text-[#c9f31d]"> */}
                        Insights
                        {/* </Link> */}
                        <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                      </div>
                    </div>
                    <ul>
                      <li>
                        <a href="/faqs">faqs</a>
                      </li>
                      <li>
                        <a href="/blog">Blogs</a>
                      </li>
                      <li>
                        <a href="/">Testimonials</a>
                      </li>
                    </ul>

                  </li>

                  {/* design */}
                  <li className='!min-w-full'>
                    <div className='bg-gradient-to-b from-zinc-400 via-slate-50 to-zinc-600 rounded-md px-3 py-2 text-center' >

                      <img
                        src="/assets/images/jbcodeapp_company_about.webp"
                        className='pt-4 pb-3 px-2 py-2 h-40 mx-auto'
                        alt="jbcodeapp company about Image" />

                      <div className="menu-heading text-white">
                        <a href="/about">
                          About Us
                        </a>

                      </div>
                      {/* <p className="sec-text text-black pb-3">
                        Clarify Project Goals for Accurate Costs and Timely Completion
                      </p> */}

                      {/* <div className='py-3'>
                        <a
                          href="what-we-do"
                          className="border-2 border-[#c9f31d] hover:border-white border-solid  rounded-lg px-4 py-2 bg-black text-white hover:bg-white hover:text-black"
                        >
                          View More
                        </a>
                      </div> */}


                    </div>
                  </li>

                  <li className='!min-w-full'>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                      <div className=" flex flex-col items-center justify-center p-2">
                        {/* <img
                          src="/assets/images/icon/googlerating.webp"
                          className="h-full w-full"
                          alt="Google Rating"
                        /> */}
                        <img src="/assets/images/icon/g1.png" className='px-1 py-2 h-16' alt="Google Rating" />

                      </div>
                      <div className=" flex flex-col items-center justify-center p-2">
                        {/* <img
                          src="/assets/images/icon/upwork-rating.png"
                          className="h-full w-full"
                          alt="Upwork Rating"
                        /> */}
                        <img src="/assets/images/icon/up1.png" className='px-1 py-2 h-16' alt="Upwork Rating" />
                      </div>
                      <div className=" flex flex-col items-center justify-center p-2">
                        {/* <img
                          src="/assets/images/icon/clutch-rating.png"
                          className="h-full w-full"
                          alt="Clutch Rating"
                        /> */}
                        <img src="/assets/images/icon/c1.png" className='px-1 py-2 h-16' alt="Clutch Rating" />

                      </div>
                      <div className=" flex flex-col items-center justify-center p-2">
                        {/* <img
                          src="/assets/images/icon/clutch-rating.png"
                          className="h-full w-full"
                          alt="Clutch Rating"
                        /> */}
                      </div>
                    </div>
                  </li>

                  {/* Contact Info */}
                  {/* <li className='px-4 '>
                    <ul className=''>
                      <li>
                        <Link href="tel:+919909918338" className='hover:text-amber-500'>
                          <div className="blog__detail-meta">
                            <img
                              src="/assets/images/icon/mobile.png"
                              alt="Author Picture"
                              className='h-12'
                            />
                            <h5>
                              (+91)990-991-8338
                            </h5>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link href="mailto:contact@jbcodeapp.com">
                          <div className="blog__detail-meta">
                            <img
                              src="/assets/images/icon/email.png"
                              alt="Author Picture"
                              className='h-12'
                            />
                            <h5 className='text-md'>
                              contact@jbcodeapp.com
                            </h5>
                          </div>
                        </Link>
                      </li>

                      <Socialmedia />

                    </ul>

                  </li> */}
                </ul>
              </li>

              {/* Let's Talk */}
              <li >
                <div className="footer__contact-3" style={{ paddingTop: "0px" }}>
                  <a className="end" style={{ fontSize: "22px" }} href="/contact">
                    Let’s talk
                  </a>
                </div>
              </li>

            </ul>
          </div>
          <div className="header__nav-icon-2">
            <button className="menu-icon-2" id="open_offcanvas">
              {/* <img src="/assets/imgs/icon/menu-white.png" alt="Menubar Icon" /> */}
              <i className="fa-solid fa-bars text-white text-2xl"></i>
            </button>
          </div>
        </div>
      </header>
      {/* Header area end */}

      {/* Offcanvas area start */}
      <div className="offcanvas__area">
        <div className="offcanvas__body">
          <div className="offcanvas__left">

            <div className="offcanvas__logo">
              <a href="index">
                <img
                  src="/assets/jbcodeapp_logo_white_logo.png"
                  alt="Offcanvas Logo"
                />
              </a>
            </div>

            <div className="offcanvas__social">
              <h3 className="social-title">Follow Us</h3>
              <ul>
                <li>
                  <a href="https://www.dribbble.com/jbcodeapp">Dribbble</a>
                </li>
                <li>
                  <a href="https://www.behance.net/jbcodeapp">Behance</a>
                </li>
                <li>
                  <a href="https://www.instagram.com/jbcodeapp">Instagram</a>
                </li>
                <li>
                  <a href="https://www.facebook.com/jbcodeapp">Facebook</a>
                </li>
                <li>
                  <a href="https://twitter.com/jbcodeapp">Twitter</a>
                </li>
                <li>
                  <a href="https://www.youtube.com//@jbcodeapp">YouTube</a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/jbcodeapp">Linkedin</a>
                </li>
              </ul>
            </div>

            <div className="offcanvas__links">
              <ul>
                <li>
                  <a href="/about">About</a>
                </li>
                <li>
                  <a href="/contact">contact</a>
                </li>
                <li>
                  <a href="/">Career</a>
                </li>
                <li>
                  <a href="/blog">blog</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="offcanvas__mid">
            <div className="offcanvas__menu-wrapper mean-container">
              <div className="mean-bar">
                <a
                  href="#nav"
                  className="meanmenu-reveal"
                  style={{ right: 0, left: "auto", display: "inline" }}
                >
                  <span>
                    <span>
                      <span />
                    </span>
                  </span>
                </a>
                <nav className="mean-nav">
                  <ul className="menu-anim" style={{ display: "none" }}>

                    {/* HIRE DEVELOPERS */}
                    {/* Services */}
                    <li>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingHeaderone">
                          <button
                            className="accordion-button  collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseHeaderone"
                            aria-expanded="false"
                            aria-controls="collapseHeaderone"
                            onClick={toggle4}

                          >
                            <a>
                              <div className="menu-text">
                                <span>HIRE DEVELOPERS</span>
                              </div>
                            </a>
                            <a className="mean-expand" href="#" style={{ fontSize: 36 }}>
                              {isOpen4 ? "-" : "+"}
                            </a>
                          </button>
                        </h2>

                        <div
                          id="collapseHeaderone"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingHeaderone"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">

                            {/* what we do/services */}
                            <ul>
                              {/* <div className="menu-heading  border-t border-b border-white">
                                <div className="relative group inline-block">
                                  <a href="/industries" className=" relative z-10 hover:text-[#c9f31d]">
                                    Hire Website Developers
                                  </a>
                                </div>
                              </div> */}

                              <li>
                                {/* <div className="  border-t border-b border-white">
                                  <div className="relative group inline-block">
                                    <a href="/services/hire-web-developers" className=" relative z-10 hover:text-[#c9f31d]">
                                      <i className="fa-solid fa-circle text-white text-sm"></i>
                                      Hire Website Developers
                                    </a>
                                  </div>
                                </div> */}
                                <a href="/services/hire-web-developers" className=" relative z-10 hover:text-[#c9f31d]">
                                  Hire Website Developers
                                </a>
                              </li>

                              <li className="whitespace-nowrap">
                                <a href="/services/web/hire-php-web-developers" className="block">Hire PHP Developers</a>
                              </li>
                              <li className="whitespace-nowrap">
                                <a href="/services/web/hire-laravel-web-developers" className="block">Hire Laravel Developers</a>
                              </li>
                              <li className="whitespace-nowrap">
                                <a href="/" className="block">Hire NodeJS Developers</a>
                              </li>
                              <li className="whitespace-nowrap">
                                <a href="/" className="block">Hire ExpressJS Developers</a>
                              </li>

                              {/* <hr /> */}

                              {/* <div className="menu-heading  border-t border-b border-white"> */}
                              {/* <div className="relative group inline-block"> */}

                              {/* <a href="/services/hire-frontend-developers" className=" relative z-10 hover:text-[#c9f31d]">
                                Hire Frontend Developers
                              </a> */}

                              {/* <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span> */}
                              {/* </div> */}
                              {/* </div> */}

                              <li>
                                {/* <div className="  border-t border-b border-white">
                                  <div className="relative group inline-block "> */}
                                    <a href="/services/hire-frontend-developers" className=" relative z-10 hover:text-[#c9f31d]">
                                      {/* <i className="fa-solid fa-circle text-white text-sm"></i> */}
                                      Hire Frontend Developers
                                    </a>
                                  {/* </div>
                                </div> */}
                              </li>

                              <li>
                                <a href="/services/web/hire-reactjs-developers" className="whitespace-nowrap">Hire ReactJS Developers</a>
                              </li>
                              <li>
                                <a href="" className="whitespace-nowrap">Hire Angular Developers</a>
                              </li>

                            {/* </ul> */}

                            {/* Hire Mobile Developers */}
                            {/* <ul> */}
                              {/* <div className="menu-heading border-t border-b border-white">
                                <div className="relative group inline-block">

                                  <a href="/industries" className="text-b relative z-10 hover:text-[#c9f31d]">
                                    <i className="fa-solid fa-circle text-white text-sm"></i>
                                    Hire Mobile Developers
                                  </a>
                                </div>
                              </div> */}

                              <li>
                                {/* <div className="  border-t border-b border-white">
                                  <div className="relative group inline-block"> */}
                                    <a href="/" className=" relative z-10 hover:text-[#c9f31d]">
                                      {/* <i className="fa-solid fa-circle text-white text-sm"></i> */}
                                      Hire Mobile Developers
                                    </a>
                                  {/* </div>
                                </div> */}
                              </li>

                              <li>
                                <a href="/">Hire iOS Developers</a>
                              </li>
                              <li>
                                <a href="/">Hire Android Developers</a>
                              </li>
                              <li>
                                <a href="/">Hire Flutter Developers</a>
                              </li>
                              <li>
                                <a href="/">Hire Swift Developers</a>
                              </li>

                              {/* <hr /> */}

                              {/* <div className="menu-heading border-t border-b border-white">
                                <div className="relative group inline-block">
                                  <a href="/industries" className="text-b relative z-10 hover:text-[#c9f31d]">
                                    <i className="fa-solid fa-circle text-white text-sm"></i>
                                    Hire Open Source Developers
                                  </a>
                                </div>
                              </div> */}

                              <li>
                                {/* <div className="  border-t border-b border-white">
                                  <div className="relative group inline-block"> */}
                                    <a href="/" className=" relative z-10 hover:text-[#c9f31d]">
                                      {/* <i className="fa-solid fa-circle text-white text-sm"></i> */}
                                      Hire Open Source Developers
                                    </a>
                                  {/* </div>
                                </div> */}
                              </li>

                              <li>
                                <a href="" className="whitespace-nowrap">Hire Wordpress Developers</a>
                              </li>
                              <li>
                                <a href="" className="whitespace-nowrap">Hire Shopify Developers</a>
                              </li>
                            </ul>

                          </div>
                        </div>
                      </div>
                    </li>

                    {/* Solution */}
                    <li>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingEleven">
                          <button
                            className="accordion-button  collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseEleven"
                            aria-expanded="false"
                            aria-controls="collapseEleven"
                            onClick={toggle1}
                          >
                            <a>
                              <div className="menu-text">
                                <span>SOLUTIONS</span>
                              </div>
                            </a>
                            <a className="mean-expand" href="#" style={{ fontSize: 36 }} >
                              {isOpen1 ? "-" : "+"}
                            </a>
                          </button>
                        </h2>
                        <div
                          id="collapseEleven"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingEleven"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">

                            <ul>
                              <li >
                                <a href="/solutions">Inventory & Warehouse</a>
                              </li>
                              <li >
                                <a href="/solutions">Financial Accounting</a>
                              </li>
                              <li >
                                <a href="/solutions">Vehicle Tracking System</a>
                              </li>
                              <li >
                                <a href="/solutions">Transport Management Solutions</a>
                              </li>
                              <li >
                                <a href="/solutions">HR Management System (HRMS)</a>
                              </li>
                              <li >
                                <a href="/solutions">Smart Station Management</a>
                              </li>
                              <li >
                                <a href="/solutions">Customer Relationship Management (CRM)</a>
                              </li>
                              <li >
                                <a href="/solutions">Smart Estate Management</a>
                              </li>
                              <li >
                                <a href="/solutions">Procurement Management System</a>
                              </li>
                              <li >
                                <a href="/solutions">Real Estate & Construction</a>
                              </li>
                              <li >
                                <a href="/solutions">Sales & Invoice Processing</a>
                              </li>
                              <li >
                                <a href="/solutions">Visitor Management System</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* Services */}
                    <li>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwelve">
                          <button
                            className="accordion-button  collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwelve"
                            aria-expanded="false"
                            aria-controls="collapseTwelve"
                            onClick={toggle2}

                          >
                            <a>
                              <div className="menu-text">
                                <span>SERVICES</span>
                              </div>
                            </a>
                            <a className="mean-expand" href="#" style={{ fontSize: 36 }}>
                              {isOpen2 ? "-" : "+"}
                            </a>
                          </button>
                        </h2>

                        <div
                          id="collapseTwelve"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwelve"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">

                            {/* what we do/services */}
                            <ul>
                              {/* <div className="menu-heading  border-t border-b border-white"> */}
                              {/* <div className="relative group inline-block"> */}
                              <li>
                                {/* <div className="  border-t border-b border-white">
                                  <div className="relative group inline-block"> */}
                                    <a href="/industries" className=" relative z-10 hover:text-[#c9f31d]">
                                      {/* <i className="fa-solid fa-circle text-white text-sm"></i> */}
                                      What We Do
                                    </a>
                                  {/* </div>
                                </div> */}
                              </li>
                              {/* <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span> */}
                              {/* </div> */}
                              {/* </div> */}

                              <li >
                                <a href="/solutions">Inventory & Warehouse</a>
                              </li>
                              <li >
                                <a href="/solutions">Financial Accounting</a>
                              </li>
                              <li >
                                <a href="/solutions">Vehicle Tracking System</a>
                              </li>
                              <li >
                                <a href="/solutions">Transport Management Solutions</a>
                              </li>
                              <li >
                                <a href="/solutions">HR Management System (HRMS)</a>
                              </li>
                              <li >
                                <a href="/solutions">Smart Station Management</a>
                              </li>
                              <li >
                                <a href="/solutions">Customer Relationship Management (CRM)</a>
                              </li>
                              <li >
                                <a href="/solutions">Smart Estate Management</a>
                              </li>
                              <li >
                                <a href="/solutions">Procurement Management System</a>
                              </li>
                              <li >
                                <a href="/solutions">Real Estate & Construction</a>
                              </li>
                              <li >
                                <a href="/solutions">Sales & Invoice Processing</a>
                              </li>
                              <li >
                                <a href="/solutions">Visitor Management System</a>
                              </li>
                            {/* </ul> */}

                            {/* Industries */}
                            {/* <ul> */}
                              {/* <div className="menu-heading border-t border-b border-white">
                                <div className="relative group inline-block">
                                  <a href="/industries" className="text-b relative z-10 hover:text-[#c9f31d]">
                                    Industries
                                  </a>
                                </div>
                              </div> */}

                              <li>
                                {/* <div className="  border-t border-b border-white">
                                  <div className="relative group inline-block"> */}
                                    <a href="/industries" className=" relative z-10 hover:text-[#c9f31d]">
                                      {/* <i className="fa-solid fa-circle text-white text-sm"></i> */}
                                      Industries
                                    </a>
                                  {/* </div>
                                </div> */}
                              </li>

                              <li>
                                <a href="/">Education / e-learning</a>
                              </li>
                              <li>
                                <a href="/">Healthcare</a>
                              </li>
                              <li>
                                <a href="/">Logistics & Distribution</a>
                              </li>
                              <li>
                                <a href="/">Finance & Insurance</a>
                              </li>
                              <li>
                                <a href="/">Legal</a>
                              </li>
                              <li>
                                <a href="/">Real Estate & Construction</a>
                              </li>
                            </ul>

                          </div>
                        </div>
                      </div>
                    </li>

                    {/* OUR WORK */}
                    <li>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThirteen">
                          <button
                            className="accordion-button  collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThirteen"
                            aria-expanded="false"
                            aria-controls="collapseThirteen"
                            onClick={toggle3}
                          >
                            <a>
                              <div className="menu-text">
                                <span>OUR WORK</span>
                              </div>
                            </a>
                            <a className="mean-expand" href="#" style={{ fontSize: 36 }}>
                              {isOpen3 ? "-" : "+"}
                            </a>
                          </button>
                        </h2>
                        <div
                          id="collapseThirteen"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingThirteen"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">

                            <ul className='items-center justify-center text-center'>

                              <ul>
                                <li>
                                  <a href="/case-studies">
                                    <div className="relative group inline-block">
                                      Case Studies
                                      {/* <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span> */}
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="/portfolio">
                                    <div className="relative group inline-block">
                                      Portfolio
                                      {/* <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span> */}
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="/">
                                    <div className="relative group inline-block">
                                      Products
                                      {/* <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span> */}
                                    </div>
                                  </a>
                                </li>
                              </ul>

                              {/* Case Studies */}
                              {/* <li className="flex justify-center">
                                <div className="pt-9">
                                  <div className="menu-heading pt-2 text-center border rounded-lg">
                                    <div className="relative group inline-block">
                                      Case Studies
                                      <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                                    </div>
                                  </div>

                                  <a href="/portfolio" className="">
                                    <img
                                      src="/assets/images/jbcodeapp_case_studies.webp"
                                      className="h-64 pb-2 rounded-lg border border-black hover:border-[#c9f31d] hover:bg-neutral-300"
                                      alt="Portfolio"
                                    />
                                  </a>
                                </div>
                              </li> */}


                              {/* Portfolio */}
                              {/* <li>
                                <div className="px-[10%] pt-9">
                                  <div className="menu-heading pt-2 text-center border rounded-lg">
                                    <div className="relative group inline-block">
                                      Portfolio
                                      <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                                    </div>
                                  </div>

                                  <a href="/portfolio" className="block mx-auto">
                                    <img
                                      src="/assets/images/our_work.svg"
                                      className="h-64 pb-2  rounded-lg border border-black hover:border-[#c9f31d] hover:bg-neutral-300"
                                      alt="Portfolio"
                                    />
                                  </a>
                                </div>
                              </li> */}


                              {/* Products */}
                              {/* <li>
                                <div className="px-[10%] pt-9">
                                  <div className="menu-heading pt-2 text-center border rounded-lg">
                                    <div className="relative group inline-block">
                                      Products
                                      <span className="absolute left-0 bottom-0 w-0 h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                                    </div>
                                  </div>

                                  <a href="/portfolio" className="block mx-auto">
                                    <img
                                      src="/assets/images/product.jpg"
                                      className="h-64 pb-2  rounded-lg border border-black hover:border-[#c9f31d] hover:bg-neutral-300"
                                      alt="Portfolio"
                                    />
                                  </a>
                                </div>
                              </li> */}

                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>

                    {/* Contact Info */}
                    {/* {isMobile ?
                      <li className=''> */}
                    {/* <div className="menu-heading text-black">Portfolio dark</div> */}
                    {/* <ul className=''>
                          <li>
                            <Link href="tel:+919909918338" className='hover:text-amber-500'>
                              <div className="blog__detail-meta">
                                <i className="fa-solid fa-square-phone text-white text-5xl "></i>
                                <h5>
                                  (+91)990-991-8338
                                </h5>
                              </div>
                            </Link>
                          </li>
                          <li className='pb-3 border-b border-white'>
                            <Link href="mailto:contact@jbcodeapp.com">
                              <div className="blog__detail-meta">
                                <i className="fa-solid fa-square-envelope text-white text-5xl "></i>
                                <h5 className='text-md'>
                                  contact@jbcodeapp.com
                                </h5>
                              </div>
                            </Link>
                          </li> */}


                    {/* <div className="menu-heading border-b border-t border-white pt-2">
                        <div className="relative group inline-block">
                          <a className="px-4 py-3 text-center rounded-md bg-zinc-950 text-slate-50 hover:bg-white hover:border hover:border-gray-500 hover:text-black" href="/contact">
                            Inquiry
                          </a>
                        </div>
                      </div>

                      <div className="menu-heading  border-b border-t text-center pt-2">
                        <div className="relative group inline-block">
                          <a className="px-4 py-3  rounded-md bg-zinc-950 text-slate-50 hover:bg-white hover:border hover:border-gray-500 hover:text-black" href="/contact">
                            Schedule Meeting
                          </a>
                        </div>
                      </div>

                      <div className="menu-heading  border-b border-t  pt-2">
                        <div className="relative group inline-block">
                          <a className="px-4 py-3 text-center  rounded-md bg-zinc-950 text-slate-50 hover:bg-white hover:border hover:border-gray-500 hover:text-black" href="/contact">
                            Contact Us
                          </a>
                        </div>
                      </div> */}

                    {/* Socila media */}
                    {/* <li className='pt-3'>
                            <div className="menu-heading  border-b border-t text-center pt-2">
                              <div className="relative group inline-block">
                                Follow us on social media
                                <span className="absolute  h-0.5 bg-[#c9f31d] transition-all duration-300 group-hover:w-full"></span>
                              </div>
                            </div>
                            <ul className="flex text-lg  py-2 text-center items-center justify-center border-b">

                              <li>
                                <a href="https://www.linkedin.com/in/jbcodeapp" className="text-gray-600">
                                  <span className="text-3xl">
                                    <i className="fa-brands fa-linkedin text-[#0077b5] hover:text-black"></i>
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a href="https://twitter.com/jbcodeapp" className="text-gray-600">
                                  <span className="text-3xl">
                                    <i className="fa-brands fa-square-twitter text-[#1da1f2] hover:text-black"></i>
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a href="https://www.facebook.com/jbcodeapp" className="text-gray-600">
                                  <span className="text-3xl">
                                    <i className="fa-brands fa-square-facebook text-[#3b5998] hover:text-black"></i>
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a href="https://www.instagram.com/jbcodeapp" className="text-gray-600">
                                  <span className="text-3xl">
                                    <i className="fa-brands fa-square-instagram text-[#e1306c] hover:text-black"></i>
                                  </span>
                                </a>
                              </li>
                              <li>
                                <a href="https://www.youtube.com//@jbcodeapp" className="text-gray-600">
                                  <span className="text-3xl">
                                    <i className="fa-brands fa-square-youtube text-[#FF0000] hover:text-black"></i>
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </li>

                        </ul>
                      </li>
                      :
                      <li >
                        <a href="/contact">Let's Talk</a>
                      </li>

                    } */}

                    <li >
                      <a href="/contact">Let's Talk</a>
                    </li>


                  </ul>
                </nav>
              </div>

              <div className="mean-push" />

            </div>
          </div>

          <div className="offcanvas__right">
            <div className="offcanvas__search">

              {/* <form action="#">
                <input type="text" name="search" placeholder="Search keyword" />
                <button>
                  <i className="fa-solid fa-magnifying-glass" />
                </button>
              </form> */}

            </div>
            <div className="offcanvas__contact">
              <h3>Get in touch</h3>
              <ul>
                <li>
                  <a href="tel:+919909918338">(+91) 990 - 991 - 8338</a>
                </li>
                <li>
                  <a href="mailto:contact@jbcodeapp.com">contact@jbcodeapp.com</a>
                </li>
                <li>536-537, 5 Square Point, M.V Circle, JahangirPura, Surat, Gujarat 395005</li>
              </ul>
            </div>
            <img src="/assets/imgs/shape/11.png" alt="shape" className="shape-1" />
            <img src="/assets/imgs/shape/12.png" alt="shape" className="shape-2" />
          </div>
          <div className="offcanvas__close">
            <button type="button" id="close_offcanvas">
              <i className="fa-solid fa-xmark" />
            </button>
          </div>
        </div>
      </div >
      {/* Offcanvas area end */}
    </>
  )
}
